import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import {graphql, useStaticQuery} from "gatsby";
import {get} from "lodash";
import axios from "axios";
import styles from '../scss/page.scss'
import {buildBreadcrumbsItem} from "../helpers";

class HonestPawsPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Honest Paws Subscribe Page | Honest Paws®'

    this.postscriptApiKey = this.props.data.site.siteMetadata.postscriptApiKey
    this.seo = {
      children: [],
      disallowIndexing: true,
      meta: {
        title: title
      },
      title: title
    }

    this.state = {
      errors: {},
      keyword: 'HONESTPAWS',
      phone_number: '',
      success: false
    }

    this.breadcrumbs = [
      buildBreadcrumbsItem('Honest Paws Subscribe Page', this.props.location.href)
    ]

    this.handleInputChange = this.handleInputChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  /**
   * Handles inputs changes
   * @param  {Event} e
   * @return {void}
   */
  handleInputChange(e) {
    const target = e.currentTarget
    const value = target.value
    const stateProp = target.name
    this.setState({
      [stateProp]: value
    })
  }

  /**
   * Submits the form
   * @param  {Event} e
   * @return {void}
   */
  submit(e) {
    e.preventDefault()
    const form = e.currentTarget
    this.setState({
      errors: {},
      submitting: true,
      success: false
    }, () => {
      let errors = {}
      const requiredFields = [
        'phone_number'
      ]
      requiredFields.forEach((field) => {
        if (!this.state[field]) {
          errors[field] = true
        }
      })
      if (Object.keys(errors).length) {
        // manage errors
        this.setState({
          errors,
          submitting: false
        })
      } else {

        const params = {}
        for (const element of form.elements) {
          if (element.name) {
            params[element.name] = element.value
          }
        }
        params['keyword'] = this.state.keyword

        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'authorization': this.postscriptApiKey
          },
          data: JSON.stringify(params),
          url: form.action,
        };

        let t = this
        axios(options).then((response) => {
          if (response.data.success) {
            // show the success message
            t.setState({
              success: true
            })
          } else {
            alert('An error occurred. Please contact our support.')
          }
          // enable the submit submit button
          t.setState({
            submitting: false
          })
          // clear the data and success message after 4 seconds.
          setTimeout(() => {
            t.setState({
              success: false,
              phone_number: ''
            })
          }, 4000)
        })
          .catch((error) => {
            alert('An error occurred. Please contact our support.')
            // enable the submit submit button
            t.setState({
              submitting: false
            })
          });
      }
    })
  }


  render() {
    const {data} = this.props
    return (
      <Layout containerClassName={`page page-honestfam`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary landing-page-header page-honestfam-body text-center px-4">
          <div className="row">
            <form method="POST" action="https://api.postscript.io/api/subscriber/add" onSubmit={this.submit} name="honestpaws-subscribe" className="col-12">
              <div className="col-12 col-xl-3 col-lg-6 mx-auto">
                <Img fluid={data.personImage.childImageSharp.fluid} alt="HonestPaws Subscribe Page" />
              </div>
              <div className="col-12 col-xl-5 col-lg-6 mx-auto">
                <h1 className="mb-0 text-primary honestfam-title">Put In Your Phone Number Below To Subscribe!</h1>
                <input type="number"  id="phone_number" name="phone_number" placeholder="Phone Number" className="input-phone mt-4 col-12 col-lg-6" value={ this.state.phone_number } onChange={this.handleInputChange} required/>
                <small className={ `form-text text-danger ${this.state.errors.phone_number ? '' : 'd-none'}` }>Please, enter your phone number.</small>
                <small className={ `form-text text-success ${this.state.success ? '' : 'd-none'}` }>Thank you for subscribing.</small>
              </div>
              <div className="col-12 col-xl-4 col-lg-6 mx-auto">
                <div className="compliance-text mt-3">
                  * By signing up via text, you agree to receive recurring automated marketing messages at the phone number
                  provided. Consent is not a condition of purchase. Reply STOP to unsubscribe. Msg & data rates may apply.
                  View our <a href="https://www.honestpaws.com/privacy-policy/" target="_blank">Privacy Policy</a> and <a href="https://www.honestpaws.com/terms-and-conditions/" target="_blank">Terms of Service</a>.
                </div>
                <button type="submit" className="btn btn-success mt-4" disabled={this.state.submitting}>Subscribe</button>
              </div>
            </form>
          </div>
        </section>
      </Layout>
    )
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
  query HonestPawsPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        postscriptApiKey
      }
    }
    personImage: file(relativePath: { eq: "person-img.jpg" }) {
      childImageSharp {
        fluid (maxWidth: 480, maxHeight: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <HonestPawsPage {...props} data={data}/>
  )
}
